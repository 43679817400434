<template>
  <div class="app-container">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <div class="info">
        <span>院系名称：</span>
        <el-input placeholder="请输入院系名称" class="w200" v-model="queryInfo.keyWord" clearable></el-input>

        <div style="width: 480px;">
          <el-button type="primary" @click="getGradeList(queryInfo)">搜索</el-button>
          <el-button type="primary" @click="addDialogVisible = true" plain>添加院系</el-button>
          <!-- 模板下载 -->
          <el-button style="height: 40px;" type="primary" plain class="ml10" @click="downloadTemplate()">模板下载</el-button>
          <!-- 导入数据信息 -->
          <el-upload class="upload-data" :action="imgBaseUrl + '/api/import/importDepartmentData'"
            :on-success="uploadSuccess" :on-error="uploadError" :show-file-list="false">
            <el-button type="primary" plain class="ml10">导入</el-button>
          </el-upload>
        </div>
      </div>

      <!-- 院系列表区域 -->
      <el-table :data="deptList" border stripe>
        <!-- stripe: 斑马条纹 border：边框-->
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="院系名称">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column prop="depscore" label="最终得分" align="center">
          <template slot-scope="scope">{{ scope.row.depscore }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" circle
              @click="showEditDialog(scope.row)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" circle
              @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo" :page-size="queryInfo.pageSize" layout="total, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </el-card>

    <!-- 添加院系的对话框 -->
    <el-dialog title="添加院系" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="100px">
        <el-form-item label="院系名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改班级的对话框 -->
    <el-dialog title="修改院系信息" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="70px">
        <el-form-item label="院系名称">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <!-- TODO  -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "@/api/users.js";
import { getHttpUrl } from "@/utils/request";
export default {
  data() {
    return {

      imgBaseUrl: getHttpUrl(),

      // 院系列表
      deptList: [],
      // 获取班级列表查询参数对象
      queryInfo: {
        // 当前页数
        page: 1,
        // 每页显示多少数据
        pageSize: 10,
        keyWord: '',// 院系名称
      },
      gradeList: [],
      total: 0,
      // 添加班级对话框
      addDialogVisible: false,
      // 班级添加
      addForm: {
        gradeNo: "",
        gradeName: "",
        deptId: ""
      },
      // 班级添加表单验证规则
      addFormRules: {
        classnum: [
          { required: true, message: "请输入院系编号", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "院系编号的长度在2～10个字",
            trigger: "blur"
          }
        ],
        name: [
          { required: true, message: "请输入院系名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "院系名称的长度在2～50个字",
            trigger: "blur"
          }
        ],
        deptId: [{ required: true, message: "请输入所属院系", trigger: "blur" }]
      },
      // 修改班级
      editDialogVisible: false,
      editForm: {},

      /**
       * 编辑班级表单验证
       */
      editFormRules: {
        classnum: [
          { required: true, message: "请输入院系编号", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入院系名称", trigger: "blur" }]
      }
    };
  },

  created() {
    let me = this;
    // 获取所有院系信息列表
    me.getDeptList();
  },

  methods: {

    /**
     * 根据查询条件获取院系信息
     */
    async getGradeList(form) {
      let me = this;
      me.queryInfo.keyWord = form.keyWord
      let res = await api.getSystemList(me.queryInfo);
      console.log(res, '院系搜索')
      if (res.status !== 1) {
        return me.$message.error("获取院系列表失败！");
      }
      me.deptList = res.data.records;
      me.total = res.data.total;
      console.log(res, me.gradeList);
    },

    /**
     * 获取所有院系信息列表
     */
    async getDeptList() {
      let me = this;
      let res = await api.getSystemList(me.queryInfo);
      if (res.status !== 1) {
        return me.$message.error("获取院系列表失败！");
      }
      console.log(res, '00res');
      me.deptList = res.data.records;
      me.total = res.data.total;
      console.log(me.deptList);
    },

    handleSizeChange(newSize) {
      let me = this;
      me.queryInfo.pageSize = newSize;
      me.getDeptList();
    },

    handleCurrentChange(newSize) {
      let me = this;
      me.queryInfo.page = newSize;
      me.getDeptList();
    },

    /**
     * 关闭新增院系信息提示框
     */
    addDialogClosed() {
      let me = this;
      me.$refs.addFormRef.resetFields();
    },

    /**
     * 新增院系信息
     */
    add() {
      let me = this;
      me.$refs.addFormRef.validate(async valid => {
        if (!valid) return;
        let res = await api.addDepartment({ ...me.addForm, classscore: 100 });
        if (res.status !== 1) {
          me.$message.error("添加院系失败！");
          return;
        }
        me.$message.success("添加院系成功！");
        me.addDialogVisible = false;
        me.getDeptList()
      });
    },

    /**
     * 显示编辑院系提示框
     * @param {*} data 
     */
    async showEditDialog(data) {
      let me = this;
      let copy = Object.assign({}, data);
      me.editForm = copy;
      me.editDialogVisible = true;
    },

    /**
     * 关闭编辑院系提示框
     */
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },

    /**
     * 更新院系信息
     */
    editUser() {
      let me = this;
      me.$refs.editFormRef.validate(async valid => {
        if (!valid) return;
        let res = await api.updataDepartment(me.editForm);
        if (res.status !== 1) {
          me.$message.error("更新院系信息失败！");
        }
        me.editDialogVisible = false;
        me.$message.success("更新院系信息成功！");
        me.getDeptList();
      });
    },

    /**
     * 根据ID删除院系信息
     * 
     * @param {*} id 院系ID
     */
    async removeById(id) {
      const confirmResult = await this.$confirm("确定删除该院系?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let res = await api.removeDepartment(id);
      if (res.status !== 1) return this.$message.error("删除院系失败！");
      this.$message.success("删除院系成功！");
      this.getDeptList();
    },

    /**
     * 导入成功回调
     * @param {*} response 
     */
    uploadSuccess(response) {
      let me = this;
      if (response.status === -1) {
        // me.$message.error(response.msg);
        me.$confirm(response.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error'
        }).then(() => { }).catch(() => { });
      } else {
        me.$message({ message: '数据导入成功', type: 'success' });
        me.getDeptList();
      }
    },

    /**
     * 文件上传失败回调
     */
    uploadError(err) {
      let me = this;
      console.log("err====== ", err);
      // me.$message.error("数据导入失败！");
      me.$confirm('数据导入失败！', '提示', {
        confirmButtonText: '确定',
        type: 'error'
      }).then(() => { }).catch(() => { });
    },

    /**
     * 模板下载
     */
    downloadTemplate() {
      window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=6"
    }
  }
};
</script>

<style lang="scss" scoped>
.grade {
  width: 100%;
}

.info {
  display: flex;
  margin-bottom: 10px;

  span {
    line-height: 41px;
  }
}
</style>